.Flex {
  display: flex;
}

/* align-items */
.align-items-center {
  align-items: center;
}
.align-items-flex-end {
  align-items: flex-end;
}
.align-items-flex-start {
  align-items: flex-start;
}

/* align-self */
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: end;
}
.align-self-flex-end {
  align-self: flex-end;
}
.align-self-flex-start {
  align-self: flex-start;
}
.align-self-start {
  align-self: start;
}
.align-self-stretch {
  align-self: stretch;
}

/* flex */
.flex-0-0-200px {
  flex: 0 0 200px;
}
.flex-0-0-250px {
  flex: 0 0 250px;
}
.flex-0-0-300px {
  flex: 0 0 300px;
}
.flex-0-0-310px {
  flex: 0 0 310px;
}
.flex-0-0-auto {
  flex: 0 0 auto;
}
.flex-0-1-100px {
  flex: 0 1 100px;
}
.flex-1-1-auto {
  flex: 1 1 auto;
}
.flex-2-2-auto {
  flex: 2 2 auto;
}
.flex-3-3-auto {
  flex: 3 3 auto;
}

/* flex-flow */
.flex-flow-column {
  flex-flow: column;
}
.flex-flow-row {
  flex-flow: row;
}
.flex-flow-row-nowrap {
  flex-flow: row nowrap;
}
.flex-flow-row-wrap {
  flex-flow: row wrap;
}

/* justify-content */
.justify-content-center {
  justify-content: center;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-space-between {
  justify-content: space-between;
}

/* justify-self */
.justify-self-center {
  justify-self: center;
}
.justify-self-end {
  justify-self: end;
}
.justify-self-flex-end {
  justify-self: flex-end;
}
.justify-self-flex-start {
  justify-self: flex-start;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-stretch {
  justify-self: stretch;
}

/* margin */
.margin--5px {
  margin: -5px;
}
.margin-0px {
  margin: 0;
}
.margin-10px {
  margin: 10px;
}
.margin-2-5px {
  margin: 2.5px;
}
.margin-5px {
  margin: 5px;
}

/* padding */
.padding-0px {
  padding: 0;
}
.padding-10px {
  padding: 10px;
}
.padding-5px {
  padding: 5px;
}