body {
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  
}

input {
  letter-spacing: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}



.background-image {
  background-repeat:  no-repeat !important;
  background-size:  cover !important;
}




@media (max-width: 600px) {
  .maxWidth600 {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .maxWidth960 {
    display: none !important;
  }
}
@media (max-width: 1280px) {
  .maxWidth1280 {
    display: none !important;
  }
}
@media (max-width: 1920px) {
  .maxWidth1920 {
    display: none !important;
  }
}